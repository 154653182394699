<template lang="">
    <div class="field">
        <div class="grid formgrid mt-6 px-4">
            <div class="field col-12">
                <div class="formgrid grid">
                    <div class="field col-6">
                        <span :style="{ color: 'red' }">*</span>
                        <label>Tipo de Importação</label>
                        <Dropdown
                            id="tipoImportacao"
                            class="tipo-importacao"
                            v-model="tipoImportacao"
                            :options="tiposImportacoes"
                            optionLabel="descricao"
                            optionValue="id"
                            placeholder="Selecione um tipo de importação"
                            @change="handleChangeTipoImportacao"
                            :class="{ 'p-invalid': submitted && !this.tipoImportacao }"
                        />
                        <small class="p-error" v-if="submitted && !this.tipoImportacao">Tipo de importação é obrigatório.</small>
                    </div>
                    <div class="field col-6" v-if="tipoImportacao === 'AC'">
                        <span :style="{ color: 'red' }">*</span>
                        <label>Cliente</label>
                        <DropdownCliente id="customer_id" v-model="cliente" :class="{ 'p-invalid': submitted && !this.cliente }" />
                        <small class="p-error" v-if="submitted && !this.cliente">Cliente é obrigatório.</small>
                    </div>
                </div>
            </div>
            <div class="col-12 p-3">
                <div class="flex justify-content-end">
                    <div class="flex align-items-center justify-content-center w-50rem font-bold text-white">
                        <Button
                            label="Cancelar"
                            class="p-button p-button-danger mr-2 w-15rem"
                            @click="
                                () => {
                                    this.$router.push('/importacao-cadastro-estrutura');
                                }
                            "
                        />
                        <Button label="Próximo" class="p-button p-button-primary w-15rem" @click="nextPage()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DropdownCliente from '../../clientes/components/DropdownCliente.vue';
import EnumTipoImportacaoExcel from '../../../enums/EnumTipoImportacaoExcel';
import EnumCadastroImportacaoExcel from '../../../enums/EnumCadastroImportacaoExcel';

export default {
    components: {
        DropdownCliente
    },
    props: {
        formData: Object
    },
    data() {
        return {
            tipoImportacao: null,
            tiposImportacoes: EnumTipoImportacaoExcel,
            cliente: null,
            clientes: [],
            submitted: false,
            validationErrors: {},
            cadastros: []
        };
    },
    created() {
        this.tipoImportacao = this.formData?.tipoImportacao;
        this.cliente = this.formData?.cliente;
    },
    async mounted() {
        await this.loadCustomers();
    },
    methods: {
        handleChangeTipoImportacao(e) {
            if (e.value !== 'AC') {
                this.cliente = null;
            }
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                let cadastrosLista = [];
                if (this.tipoImportacao === 'NC') {
                    cadastrosLista = [
                        {
                            id: 'CL',
                            descricao: 'Cliente'
                        },
                        ...EnumCadastroImportacaoExcel
                    ];

                    if (!this.formData?.cadastros) {
                        this.$emit('setFormData', [
                            {
                                id: 'CL',
                                descricao: 'Cliente'
                            }
                        ]);
                    } else {
                        if (!this.formData?.cadastros?.find((cad) => cad.id === 'CL')) {
                            this.$emit('setFormData', [
                                {
                                    id: 'CL',
                                    descricao: 'Cliente'
                                },
                                ...this.formData.cadastros
                            ]);
                        }
                    }
                } else {
                    cadastrosLista = EnumCadastroImportacaoExcel;
                    const cadastros = this.formData.cadastros;
                    const cadIndex = cadastros?.findIndex((cad) => cad.id === 'CL');
                    if (cadIndex >= 0) {
                        cadastros.splice(cadIndex, 1);
                        this.$emit('setFormData', cadastros);
                    }
                }

                this.$emit('next-page', {
                    formData: {
                        clienteId: this.cliente?.id,
                        cadastrosLista,
                        tipoImportacao: this.tipoImportacao,
                        step: 2,
                        link: ['/importacao-cadastro-estrutura/tipo-importacao']
                    },
                    pageIndex: 0
                });
            }
        },
        validateForm() {
            if (!this.tipoImportacao) this.validationErrors['tipoImportacao'] = true;
            else delete this.validationErrors['tipoImportacao'];

            if (!this.cliente && this.tipoImportacao === 'AC') this.validationErrors['cliente'] = true;
            else delete this.validationErrors['cliente'];

            return !Object.keys(this.validationErrors).length;
        }
    }
};
</script>

<style scoped lang="scss">
::v-deep(.tipo-importacao) {
    border-radius: 6px;
}

#customer_id {
    border-radius: 6px;
}
</style>
