const EnumTipoImportacaoExcel = Object.freeze([
    {
        id: 'AC',
        descricao: 'Atualização Cadastral'
    },
    {
        id: 'NC',
        descricao: 'Novo Contrato'
    }
]);

export default EnumTipoImportacaoExcel;
