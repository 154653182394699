const EnumCadastroImportacaoExcel = Object.freeze([
    {
        id: 'UN',
        descricao: 'Unidade'
    },
    {
        id: 'SE',
        descricao: 'Setor'
    },
    {
        id: 'CA',
        descricao: 'Cargo'
    },
    {
        id: 'FU',
        descricao: 'Funcionário'
    }
]);

export default EnumCadastroImportacaoExcel;
